// src/App.js
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="construction-container">
      <h1 className="animated-text">En construcción</h1>
      <h2 className="company-name">SERSIT CUSCO SAC</h2>
      <p className="coming-soon">Pronto estaremos en línea con nuestro nuevo site</p>
    </div>
  );
}

export default App;
